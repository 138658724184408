import React, { useMemo } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { Descriptions } from 'antd';
import { AppRouterEnum } from '../../Types/Enums/AppRouter.enum';
import PageContainer from '../../Components/Atoms/PageContainer';
import { useCandidateQuery } from '../../Graphql/generated';
import DefaultLoading from '../../Components/Atoms/DefaultLoading';
import { formatDateLuxon } from '../../Utils/formatDateLuxon';
import SelfDeclarationDetail from './Components/SelfDeclarationDetail';
import AudioDetail from './Components/AudioDetail';
import ChartDetail from './Components/ChartDetail';
import SkillsDetail from './Components/SkillsDetail';
import BehavioralV2Detail from './Components/BehavioralV2Detail';

function CandidatesDetail() {
  const { id } = useParams();

  const { loading, data, refetch } = useCandidateQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: { id: id! },
  });

  const candidate = useMemo(() => data?.candidate, [data?.candidate]);
  const selfDeclaration = useMemo(() => candidate?.selfDeclaration, [candidate?.selfDeclaration]);
  const behavioralV2 = useMemo(() => candidate?.behavioralV2, [candidate?.behavioralV2]);
  const audio = useMemo(() => candidate?.audio, [candidate?.audio]);
  const chart = useMemo(() => candidate?.chart, [candidate?.chart]);
  const techs = useMemo(() => candidate?.techSkills, [candidate?.techSkills]);
  const languages = useMemo(() => candidate?.languageSkills, [candidate?.languageSkills]);

  const hasBehavioralV2 = useMemo(() => (
    !!(behavioralV2?.id && behavioralV2?.checkComplexInfoId)
  ), [behavioralV2]);

  return (
    <PageContainer
      routes={[
        { path: '/', Icon: <HomeOutlined /> },
        { label: 'Geral' },
        { path: AppRouterEnum.CANDIDATES_PREFIX, label: 'Candidatos' },
        { label: 'Visualizar' },
      ]}
      loading={loading}
    >
      {(candidate && !loading) ? (
        <Content>
          <br />
          <br />

          <Descriptions bordered title="Dados básicos" size="small">
            <Descriptions.Item label="ID">{candidate.id}</Descriptions.Item>
            <Descriptions.Item label="E-mail">{candidate.email}</Descriptions.Item>
            <Descriptions.Item label="Criado em">{formatDateLuxon(candidate.createdAt)}</Descriptions.Item>
          </Descriptions>

          <br />
          <br />
          <br />

          {!!selfDeclaration && (
            <SelfDeclarationDetail selfDeclaration={selfDeclaration} />
          )}

          <br />
          <br />
          <br />

          {!!(audio) && (
            <AudioDetail
              audio={audio}
              email={candidate.email}
              hasAnalysis={!!chart?.length}
              hasBehavioralV2={hasBehavioralV2}
              onReload={refetch}
            />
          )}

          <br />
          <br />
          <br />

          {!!(techs?.length || languages?.length) && (
            <SkillsDetail
              techs={techs || []}
              languages={languages || []}
            />
          )}

          {!!(hasBehavioralV2 && behavioralV2) && (
            <>
              <br />
              <br />
              <br />

              <BehavioralV2Detail behavioral={behavioralV2} />
            </>
          )}

          {(hasBehavioralV2 && chart?.length) && (
            <>
              <br />
              <br />
              <br />

              <ChartDetail data={chart} />
            </>
          )}

          <br />
          <br />
          <br />
        </Content>
      ) : (
        <DefaultLoading />
      )}
    </PageContainer>
  );
}

export default CandidatesDetail;
