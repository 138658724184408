import React from 'react';
import { Descriptions } from 'antd';
import Title from 'antd/lib/typography/Title';
import { CandidateDetailBehavioralV2Output } from '../../../../Graphql/generated';
import { useBehavioralQuestions } from './useBehavioralQuestions';

interface IBehavioralDetail {
  behavioral: CandidateDetailBehavioralV2Output;
}

function BehavioralV2Detail({ behavioral }: IBehavioralDetail) {
  const items = useBehavioralQuestions(behavioral);

  return (
    <>
      <Title level={5}>Análise comportamental V2</Title>

      <Descriptions bordered size="small" column={1}>
        {items.map((item) => (
          <Descriptions.Item key={`analise-desc-${item.title}`} label={item.title}>
            <span style={{ whiteSpace: 'nowrap' }}>{item.value}</span>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </>
  );
}

export default BehavioralV2Detail;
