import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  LinearScale,
} from 'chart.js';
import { Tooltip as TooltipAnt, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CandidateQuery } from '../../../../Graphql/generated';
import {
  DivBar, DivBarsWrapper, DivChartWrapper, DivVisualWrapper, LiAderenciaItem, LiChartItem,
} from './ChartDetail.styles';
import LogoIA from '../../../../Components/Atoms/LogoIA/LogoIA';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  LinearScale,
);

interface IChartDetail {
  data: CandidateQuery['candidate']['chart']
}

function ChartDetail({ data }: IChartDetail) {
  const totalPercent = useMemo(() => {
    if (!data?.length) return 0;

    const sumCandidateScores = data?.reduce((acc, item) => acc + item.candidatePercentual, 0);
    const sumExpectedScores = data?.reduce((acc, item) => acc + item.expectedPercentual, 0);

    return ((sumCandidateScores / sumExpectedScores) * 100).toFixed(0);
  }, [data]);

  const hasAnalysis = useMemo(
    () => data?.some((item) => item.analysis),
    [data],
  );

  if (!data?.length) return null;

  return (
    <div>
      <Title level={hasAnalysis ? 4 : 5}>Comportamental</Title>

      {!!hasAnalysis && (
        <>
          <div style={{ textAlign: 'center', margin: '-120px auto' }}>
            <LogoIA />
          </div>

          <Typography style={{ lineHeight: '150%', fontSize: 16 }}>
            A Timbre utiliza Metodologia Própria, com o auxílio de Inteligência Artificial
            Generativa, para a análise comportamental e técnica dos profissionais inscritos
            em nossa plataforma e aplicados a uma vaga disponível.
            <br />
            <br />
            A análise abaixo levou em consideração as respostas dos profissionais na etapa
            Comportamental e na etapa Áudios. A barra “Esperado” representa a pontuação máxima
            estipulado pela Metodologia Timbre para o nível profissional do candidato (Júnior,
            Pleno ou Sênior). A barra “Candidato” representa a pontuação
            alcançada pelo profissional inscrito.
          </Typography>

          <div className="py-4" />
        </>
      )}

      <DivChartWrapper>
        {data?.map((item) => (
          <LiChartItem key={`item-${item.key}`}>
            {item.analysis ? (
              <TooltipAnt title={item.description} placement="topLeft">
                <h4>
                  {item.label}
                  <span className="ml-2"><InfoCircleOutlined style={{ color: '#5e6d7b' }} /></span>
                </h4>
              </TooltipAnt>
            ) : (
              <h4>{item.label}</h4>
            )}

            <p>{item.analysis || item.description}</p>

            <DivVisualWrapper>
              <DivBarsWrapper>
                <div>
                  <DivBar className="bar">
                    <div className="fill" style={{ width: `${item.expectedPercentual * 100}%` }} />
                  </DivBar>
                </div>

                <div>
                  <DivBar className="bar -pink">
                    <div className="fill" style={{ width: `${item.candidatePercentual * 100}%` }} />
                  </DivBar>
                </div>
              </DivBarsWrapper>

              <div>
                <p>Esperado</p>
                <p>Candidato</p>
              </div>
            </DivVisualWrapper>
          </LiChartItem>
        ))}

        <LiAderenciaItem className="-aderencia">
          <h4>% Aderência comportamental do candidato</h4>

          <p>
            O número abaixo representa o percentual de aderência da pessoa candidata ao nível dela.
            É importante observar que na medida que a intersecção de diversidade é configurada,
            a aderência pode ser impactada.
          </p>

          <p><span>{`${totalPercent}%`}</span></p>
        </LiAderenciaItem>
      </DivChartWrapper>
    </div>
  );
}

export default ChartDetail;
